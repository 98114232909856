import Toastify from "toastify-js";

window.showToast = function showToast(text = null, type = 'success') {
    if (text !== null) {
        Toastify({
            text: text,
            duration: 4000,
            newWindow: true,
            close: true,
            gravity: "bottom",
            position: "right",
            className: type,
            stopOnFocus: true,
        }).showToast();
    }
};
